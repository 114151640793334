<template>
    <div class="container">
        <div class="head">
            <div @click="$router.go(-1)">
                <van-icon name="cross" size="21" color="#fff" />
            </div>
            <div class="title">{{ $t('register').title }}</div>
            <!-- <span @click="showLanguage = true">{{ $t('register').lang }}</span> -->
        </div>
        <div class="logo">
            <!-- {{ showCompanyName() }} -->
            <img :src="basicConfig.SquareLogo" alt="" style="height: 200px;width: 200px;" />
        </div>
        <div class="form">
            <div class="item">
                <p>{{ $t('register').Account }}：</p>
                <input type="text" v-model="form.UserName" :placeholder="$t('register').placeholder" />
            </div>
            <div class="item" v-if="basicConfig.IsRegPhoneNumber">
                <p>{{ $t('register').PhoneNumber }}：</p>
                <input type="text" v-model="form.PhoneNumber" :placeholder="$t('register').placeholder4" />
            </div>
            <div class="item">
                <p>{{ $t('register').Password }}：</p>
                <input type="password" v-model="form.Password" :placeholder="$t('register').placeholder1" />
            </div>
            <div class="item">
                <p>{{ $t('register').ConfirmPassword }}：</p>
                <input type="password" v-model="form.subPassword" :placeholder="$t('register').placeholder2" />
            </div>
            <div class="item" v-if="basicConfig.IsShowInvitation">
                <p>{{ $t('register').InvitationCode }}：</p>
                <input v-model="form.InvitationCode" :placeholder="$t('register').placeholder3" />
            </div>
        </div>
        <div class="btn" @click="handleRegister">{{ $t('register').btn }}</div>
        <div class="login">
            {{ $t('register').haveAccount }}
            <router-link tag="span" to="Login">{{
                $t('register').Login
            }}</router-link>
        </div>
        <!-- <div class="footer">
            <div class="flex-c">
                <div class="text-foot">{{ $t('login').renzheng }}</div>
            </div>
            <div class="flex-c">
                <img class="img-foot" src="@/assets/newHome/beian.png" alt="" />
                <div class="text-foot">{{ $t('login').beian }}</div>
            </div>
        </div> -->
        <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
    </div>
</template>

<script>
import Language from '@/components/language'
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { getUrlParam } from '@/utils/validate'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
export default {
    components: {
        Language,
    },
    data() {
        return {
            showLanguage: false,
            form: {
                UserName: '',
                Password: '',
                subPassword: '',
                InvitationCode: '',
                PhoneNumber: ''
            },
        }
    },
    computed: {
        ...mapGetters(['basicConfig']),
    },
    methods: {
        showCompanyName() {
            let basicConfig = this.basicConfig
            let lang = this.$store.getters.lang
            if (lang === 'zh') {
                return basicConfig.CompanyName
            } else if (lang === 'en') {
                return basicConfig.CompanyNameEnglish
            } else if (lang === 'ft') {
                return basicConfig.CompanyNameTraditional
            } else {
                return basicConfig.CompanyNameJapanese
            }
        },
        async handleLogin() {
            let res = await userApi.getRefreshToken({
                username: this.form.UserName,
                password: this.form.Password,
            })
            res = await userApi.login(res.Token)
            Token.set(res.Token)
            EventBus.$emit('Login')

            setTimeout(() => {
                this.$router.push({ name: 'Product' })
            }, 1000)
        },
        async handleRegister() {
            if (!this.checkForm()) {
                return
            }
            await userApi.register(this.form)
            this.$toast.success(this.$t('register').success)
            this.handleLogin()
        },
        checkForm() {
            let form = { ...this.form }
            //用户名校验,必须字母开头6-18位
            let patternUserName = /^[a-zA-Z][a-zA-Z0-9]{5,17}$/
            if (form.UserName === '' || !patternUserName.test(form.UserName)) {
                this.$toast({
                    message: this.$t('register').placeholder,
                    position: 'bottom',
                })
                return false
            }
            //手机号校验
            if (this.basicConfig.IsRegPhoneNumber) {
                let patternPhone = /^1[3456789]\d{9}$/
                if (form.PhoneNumber === '' || !patternPhone.test(form.PhoneNumber)) {
                    this.$toast({
                        message: this.$t('register').placeholder4,
                        position: 'bottom',
                    })
                    return false
                }
            }
            //密码校验,密码由字母数字和下划线组成，且大于等于6个字符。
            let patternPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/
            if (!patternPassword.test(form.Password)) {
                this.$toast({
                    message: this.$t('register').message,
                    position: 'bottom',
                })
                return false
            }
            if (form.subPassword.length < 6) {
                this.$toast({
                    message: this.$t('register').message1,
                    position: 'bottom',
                })
                return false
            }
            if (form.subPassword !== form.Password) {
                this.$toast({
                    message: this.$t('register').message2,
                    position: 'bottom',
                })
                return false
            }
            // if (form.InvitationCode === '') {
            //   this.$toast({
            //     message: this.$t('register').placeholder3,
            //     position: 'bottom'
            //   })
            //   return false
            // }
            return true
        },
    },
    mounted() {
        this.form.InvitationCode = getUrlParam('InvitationCode')
    },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl';

.container {
  width: 100%;
  min-height: 100vh;
  color: #e9bd41;
  padding-top: 50px;
  font-size: 14px;
  background: #1f1f1f;
  text-align: center;
}

.head {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 99;
  color: #fff;
  width: 100%;
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  .title {
    font-size: 16px;
    margin-right: 40%;
  }

  span {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border: 1px solid #999;
    border-radius: 10px;
    color: #999;
  }
}

.logo {
  margin-top: 15%;
  width: 50%;
  transform :translateX(50%);
}

.form {
  text-align: left;
  margin: 30px auto;
  width: 300px;
  color: #fff;

  .item {
    margin: 25px auto;

    p {
      margin: 7.5px 0;
      line-height: 19px;
    }

    input {
      box-sizing: border-box;
      background: #2a2929;
      height: 40px;
      border: 1Px solid #333a43;
      width: 300px !important;
      padding: 0 10px;
    }
  }
}

.btn {
  height: 44px;
  border: 1Px solid #e9bd41;
  line-height: 44px;
  width: 300px;
  margin: 40px auto 20px;
}

.login {
  color: #ffffff;
  margin-top: 20px;

  span {
    color: #e9bd41;
  }
}

.footer {
  margin-top 50px;
  left: 0;
  bottom: 0;
  width: 100%;
  //background: #2a2929;
  padding: 15px 0;

  .flex-c {
    display: flex;
    align-items: center;
    justify-content: center;

    height 30px {
      .img-foot {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        margin-top: 0;
      }
    }

    .text-foot {
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
